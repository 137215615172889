import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'react-bootstrap/Image';
import Lightbox from '../components/lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Layout from '../components/layout';
import DynamicImages from '../components/dynamicImage';
import '../styles/news.scss';

const backendAddress = process.env.GATSBY_COCKPIT_URL;

class Gallery extends React.Component {
  state = {
    isOpen: {},
  };

  hideLightbox = id => {
    this.setState(prevState => ({
      isOpen: {
        ...prevState.isOpen,
        [id]: false,
      },
    }));
  };

  render() {
    const { isOpen } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query galleryQuery {
            gallery: cockpit {
              singleton(name: "Gallery")
            }
          }
        `}
        render={data => (
          <Layout>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <div className="mt-5 mb-5">
                {data.gallery.singleton.outside &&
                  data.gallery.singleton.outside.length > 0 && (
                    <h2 className="head-underlined">Wizualizacje zewnętrzne</h2>
                  )}
                <div className="row mt-3">
                  {data.gallery.singleton.outside.map((img, idx) => (
                    <div
                      key={`${img.meta.asset}-${idx}`}
                      className="col-12 col-md-6 mt-3 mb-3 d-flex hover-container"
                      style={{
                        position: 'relative',
                        height: '287px',
                        cursor: 'pointer',
                        height: 'fit-content',
                      }}
                      onClick={() =>
                        this.setState(prevState => ({
                          isOpen: {
                            ...prevState.isOpen,
                            [1]: true,
                          },
                        }))
                      }
                    >
                      <FontAwesomeIcon
                        className="show-hover"
                        icon={faPlusCircle}
                        size="3x"
                        style={{
                          position: 'absolute',
                          zIndex: 1,
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                        }}
                      />
                      <DynamicImages src={`${backendAddress}${img.path}`} />

                      <i className="fa fa-plus" />
                    </div>
                  ))}
                </div>
                <Lightbox
                  gallery={data.gallery.singleton.outside}
                  id={1}
                  hideLightbox={this.hideLightbox}
                  isOpen={isOpen[1]}
                />
              </div>
              <div className="mt-5 mb-5">
                {data.gallery.singleton.inside &&
                  data.gallery.singleton.inside.length > 0 && (
                    <h2 className="head-underlined">Wizualizacje wnętrz</h2>
                  )}
                <div className="row mt-3">
                  {data.gallery.singleton.inside.map((img, idx) => (
                    <div
                      key={`${idx}-${img.meta.asset}`}
                      className="col-12 col-md-6 mt-3 hover-container"
                      style={{
                        paddingTop: '15px',
                        position: 'relative',
                      }}
                    >
                      <FontAwesomeIcon
                        className="show-hover"
                        icon={faPlusCircle}
                        size="3x"
                        style={{
                          position: 'absolute',
                          zIndex: 1,
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                        }}
                      />

                      <Image
                        style={{
                          cursor: 'pointer',
                        }}
                        fluid
                        src={`${backendAddress}${img.path}`}
                        onClick={() =>
                          this.setState(prevState => ({
                            isOpen: {
                              ...prevState.isOpen,
                              [2]: true,
                            },
                          }))
                        }
                      />
                    </div>
                  ))}
                </div>
                <Lightbox
                  gallery={data.gallery.singleton.inside}
                  id={2}
                  hideLightbox={this.hideLightbox}
                  isOpen={isOpen[2]}
                />
              </div>
            </div>
          </Layout>
        )}
      />
    );
  }
}

export default Gallery;
